//https://firebase.google.com/docs/auth/web/start

import { data } from "cypress/types/jquery";
import { useI18n } from "vue-i18n";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import {
  collection,
  CollectionReference,
  doc,
  DocumentData,
  DocumentReference,
  getDoc,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import useAuth from "./useAuth";
import { User } from "~~/types/types";

export const createUser = async (email: string, password: string) => {
  const auth = useAuth();
  return await createUserWithEmailAndPassword(auth, email, password).catch(
    (error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
    }
  );
};

export const signInUser = async (email: string, password: string) => {
  const auth = useAuth();
  const credentials = await signInWithEmailAndPassword(
    auth,
    email,
    password
  ).catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
  });

  return credentials;
};

// This is just a helper to add the type to the db responses
export const createCollection = <T = DocumentData>(collectionName: string) => {
  return collection(getFirestore(), collectionName) as CollectionReference<T>;
};

export const useGetUserDoc = async (ref: DocumentReference) => {
  if (ref) {
    const docSnap = await getDoc(ref);
    if (docSnap.exists()) {
      const data = docSnap.data() as User;
      const user: User = {
        uid: data.uid,
        email: data.email,
        displayName: data.displayName,
        photoURL: data.photoURL,
        locale: data.locale,
        hasPassword: data.hasPassword,
      };
      return user;
    }
  } else {
    console.warn("useGetUserDoc: not valid ref provided");
    return null;
  }
};